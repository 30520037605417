import axios from 'axios'

axios.defaults.timeout = 60000

// let httpUrl = 'http://localhost:8103'
let httpUrl = 'http://dianyu.dianyu001.com'

axios.interceptors.request.use(config => {
  config.headers['x-requested-width'] = 'XMLHttpRequest';
  config.headers['Authorization'] = window.localStorage.getItem('loginToken');
  config.headers['Content-Type'] = 'application/json';
  return config;
},
error => {
  return Promise.reject(error);
});

/* axios.interceptors.response.use(
  response => {
    if (response.data.code === 1 && response.data.message === '登录超时') {
    }
    return response;
  },
  error => {
    if (error.response) {
      switch (error.response.status) {
      }
    }
    return Promise.reject(error)
  }
); */

/**
 * 封装request方法
 * @param url
 * @param data
 * @returns {Promise}
 */

export function request (url, method, data = {}, headers = {}, responseType) {
  return new Promise((resolve, reject) => {
    let token = window.localStorage.getItem('JETToken')
    if (token) {
      headers.token = token
    }
    axios
      .request({
        url: formatUrl(url),
        method: method,
        data: data,
        headers: headers,
        responseType: responseType ? `${responseType}` : 'json'
      })
      .then(response => {
        resolve(response.data)
      })
      .catch(err => {
        console.log('err', err)
        reject(err)
      });
  });
}

/**
 * 封装get方法
 * @param url
 * @param params
 * @returns {Promise}
 */

export function get (url, params = {}, headers = {}) {
  return new Promise((resolve, reject) => {
    // let token = window.localStorage.getItem("JETToken");
    // if (token) {
    //   headers.token = token;
    // }

    axios.get(httpUrl + url, {
      params: params,
      headers: headers
    }).then(response => {
      resolve(response.data)
    }).catch(err => {
      //alert(err.response.data.resultCode)
      if(401 === err.response.status) {
        alert("请登录后再操作。");
      }else{
        reject(err);
      }
    });
  });
}

/**
 * 封装post请求
 * @param url
 * @param data
 * @returns {Promise}
 */
export function post (url, data = {}, headers = {}, responseType) {
  return new Promise((resolve, reject) => {
    // let token = window.localStorage.getItem("JETToken")
    // if (token) {
    //   headers.token = token
    // }
    axios.post(httpUrl + url, data, {
      headers
    }, responseType).then(
      response => {
        resolve(response.data)
      },
      err => {
        if(401 === err.response.status) {
          alert("请登录后再操作。");
        }else{
          reject(err);
        }
      }
    );
  });
}


export function getpay (url, params = {}, headers = {}) {
  return new Promise((resolve, reject) => {
    // let token = window.localStorage.getItem("JETToken");
    // if (token) {
    //   headers.token = token;
    // }

    axios.get(url, {
      params: params,
      headers: headers
    }).then(response => {
      resolve(response)
    }).catch(err => {

    });
  });
}

/**
 * 封装post支付请求
 * @param url
 * @param data
 * @returns {Promise}
 */
export function postpay (url, data = {}, headers = {}, responseType) {
  return new Promise((resolve, reject) => {
    // let token = window.localStorage.getItem("JETToken")
    // if (token) {
    //   headers.token = token
    // }
    axios.post(url, data, {
      headers
    }, responseType).then(
      response => {
        resolve(response)
      },
      err => {

      }
    );
  });
}

/**
 * 封装patch请求
 * @param url
 * @param data
 * @returns {Promise}
 */

export function patch (url, data = {}) {
  return new Promise((resolve, reject) => {
    axios.patch(formatUrl(url), data).then(
      response => {
        resolve(response.data)
      },
      err => {
        reject(err)
      }
    );
  });
}

/**
 * 封装put请求
 * @param url
 * @param data
 * @returns {Promise}
 */

export function put (url, data = {}) {
  return new Promise((resolve, reject) => {
    axios.put(formatUrl(url), data).then(
      response => {
        resolve(response.data)
      },
      err => {
        reject(err)
      }
    );
  });
}

/**
 * 封装delete方法
 * @param url
 * @param params
 * @returns {Promise}
 */

export function del (url, params = {}, headers = {}) {
  return new Promise((resolve, reject) => {
    axios
      .delete(formatUrl(url), {
        params: params,
        headers: headers
      })
      .then(response => {
        resolve(response.data)
      })
      .catch(err => {
        reject(err)
      })
  });
}


function formatUrl (url) {
  if (url.indexO('?') > 0) {
    return `${url}&_t=${Date.now()}`
  } else {
    return `${url}?_t=${Date.now()}`
  }
}
