import { createApp } from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'

import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import {post, get } from './utils/http.js'
//  使用中文
import 'dayjs/locale/zh-cn'
import locale from 'element-plus/lib/locale/lang/zh-cn'

const app = createApp(App);
app.config.globalProperties.$post = post
app.config.globalProperties.$get = get

app.use(router);
app.use(store);
app.use(ElementPlus,{locale});
app.mount('#app');
